<template>
  <div>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card
          class="custom-card mx-auto elevation-0"
          style="border: 2px solid #4ca3ad"
        >
          <v-card-title
            v-if="$vuetify.breakpoint.width > 600"
            class="d-flex justify-center mt-n13"
          >
            <span
              style="
                display: inline-block;
                font-size: 1.2em;
                font-weight: bold;
                color: white;
                background-color: #4ca3ad;
                width: 60%;
                text-align: center;
              "
              >งวดวันที่ 1 ธันวาคม 2566</span
            >
          </v-card-title>
          <v-card-title
            v-else
            class="d-flex justify-center"
            style="margin-top: -70px"
          >
            <span
              style="
                display: inline-block;
                font-size: 1em;
                font-weight: bold;
                color: white;
                background-color: #4ca3ad;
                width: 60%;
                text-align: center;
              "
              >งวดวันที่ 1 <br />
              ธันวาคม 2566</span
            >
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
};
</script>

<style scoped>
.custom-card {
  background-color: rgb(255, 255, 255);
  width: 95%;
  max-width: 700px;
  min-height: 300px;
  margin-top: 25px;
  border-radius: 7px;
  padding: 20px;
}
</style>
